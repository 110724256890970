import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { LoggedUser } from 'src/app/models/logged-user';
import { AgencyService } from 'src/app/services/agency.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { WalletService } from 'src/app/services/wallet.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { hide, show } from '@intercom/messenger-js-sdk';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  activeTab!: string;
  expanded: boolean = false;
  dropdownExpanded: string = 'none';
  showDropdown:boolean=false
  showSubDropdown:boolean = false
  businessName: string = (localStorage.getItem('businessname')!);
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    public elementRef: ElementRef,
    public sharedService: SharedDataService,
    public websocketService: WebSocketService,
    public walletService: WalletService,
    public agencyService: AgencyService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'aiagents',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/aiagents.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'oneinbox_logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/oneinbox_logo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/settings.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'person',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/person.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'account_grey',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/account_grey.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'wallet',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/wallet.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'calls',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/calls.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'help',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/help_outline.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'document_scanner',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/document_scanner.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'support',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/support_agent.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'user_group',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/user_group.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'navigate_next',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/navigate_next.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'roadmap',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/roadmap_icon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'clients',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/clients.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'infobase',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/infobase.svg'
      )
    );
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    if(window.innerWidth < 426) { 
      console.log("Less than")
      this.expanded = true;
    } else {
      console.log("Greater than")
    }
  }
  // @HostListener('mouseenter') onMouseEnter() {
  //   this.elementRef.nativeElement.classList.add('expanded');
  // }
  
  // @HostListener('mouseleave') onMouseLeave() {
  //     this.elementRef.nativeElement.classList.remove('expanded');
  // }

  ngOnInit(): void {
    hide()
    this.getActiveTab();
    this.getWallet();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.getActiveTab());

    this.sharedService.userChange.subscribe((data) => {
      this.user = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
    })
    
  }

  getActiveTab() {
    let activeRoute = this.router.url.split('/');
    console.log(activeRoute)
    this.activeTab = activeRoute[1].split('?')[0];
    if(activeRoute?.length > 2) {
      if(this.activeTab === 'customers' && activeRoute[2].split('?')[0] === 'customer-set') this.activeTab = 'customer-set'
    }
  }

  getWallet() {
    this.walletService.getWallet().subscribe({
      next: resp => {
        console.log(resp);
        this.walletService.walletBalance = Math.floor(resp.body.data.paid_mins_consumed + resp.body.data.sub_mins_consumed);
        this.walletService.totalMinutes = Math.floor(resp.body.data.paid_total_mins + resp.body.data.sub_total_mins)
      }
    })
  }

  helpDropdownClicked(){
    this.showDropdown=!this.showDropdown
    this.showSubDropdown=false
  }
  communityClicked(){
    this.showSubDropdown=!this.showSubDropdown
  }
  handleClickedOutside(){
    this.showDropdown=false
    this.showSubDropdown=false
  }

  goToSubscription() {
    this.router.navigate(['/billing/wallet']);
  }

  openIntercom() {
    show()
  }
}
