export class InfoBase {
  private _id: number
  private _name: string
  private _asset_url: string
  private _asset_type: string
  private _created_at: string
  private _updated_at: string
  private _business: number
  private _added_by: number
  private _user: InfoBaseUser;

  constructor(infoBaseJson: any) {
    this._id = infoBaseJson?.id;
    this._name = infoBaseJson?.name;
    this._asset_url = infoBaseJson?.asset_url;
    this._asset_type = infoBaseJson?.asset_type;
    this._created_at = infoBaseJson?.created_at;
    this._updated_at = infoBaseJson?.updated_at;
    this._business = infoBaseJson?.business;
    this._added_by = infoBaseJson?.added_by;
    this._user = new InfoBaseUser(infoBaseJson?.user);
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get asset_url(): string {
        return this._asset_url;
    }

    public set asset_url(asset_url: string) {
        this._asset_url = asset_url;
    }

    public get asset_type(): string {
        return this._asset_type;
    }

    public set asset_type(asset_type: string) {
        this._asset_type = asset_type;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): string {
        return this._updated_at;
    }

    public set updated_at(updated_at: string) {
        this._updated_at = updated_at;
    }

    public get business(): number {
        return this._business;
    }

    public set business(business: number) {
        this._business = business;
    }

    public get added_by(): number {
        return this._added_by;
    }

    public set added_by(added_by: number) {
        this._added_by = added_by;
    }

    public get user(): InfoBaseUser {
        return this._user;
    }

    public set user(user: InfoBaseUser) {
        this._user = user;
    }

}

export class InfoBaseUser {
  private _id: number
  private _name: string

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }


  constructor(infoBaseUserJson: any) {
    this._id = infoBaseUserJson?.id;
    this._name = infoBaseUserJson?.name;
  }
}