import { Injectable } from '@angular/core';
import { AppSettings } from './app.settings';

@Injectable({
  providedIn: 'root'
})
export class GlobalStrings {

  // Not used
  // public static chats: string = 'Chats';
  // public static customers: string = 'Contacts';
  // public static campaign: string = 'Campaigns';
  // public static settings: string = 'Settings';
  // public static marketing: string = 'marketing';
  // public static Marketing: string = 'Marketing';
  // public static Save: string = 'Save';
  // public static Cancel: string = 'Cancel';
  // public static Edit: string = 'Edit';
  // public static runCampaign: string = 'Run Campaign';
  // public static Delete: string = 'Delete';
  // public static createCampaignButton: string = '+ Create campaign'; 

  /* Note strings*/
  
  /* Confirmation messages */
  

  /* Regex */
  public static websiteRegex: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  public static templateVariableRegex: string = '{{[0-9][0-9]*}}';
  public static templateNewLineRegex: string = '\n\n\n'
  public static newUrlRegex: RegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
  public static boldRegex: RegExp = /\*\*(.*?)\*\*/g;
  public static italicRegex: RegExp = /(?:^|\s)(_)([^_\s](?:(?=(\\?))\3.)*?[^_\s])_(?:\s|$)/g;
  public static strikeRegex: RegExp = /(?:^|\s)(~)(~?[^~\s](?:(?=(\\?))\3.)*?[^~\s])~(?:\s|$)/g;
  public static emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static templateNameRegex: RegExp = /^[A-Za-z0-9_-]+$/;

  /* Error string */
  public static invalidImageType: string = 'Only jpeg, jpg & png files are allowed';
  public static invalidImageSize: string = 'Please upload images less than 1MB';
  public static enterValidField: string = 'Enter {field} field';
  public static enterValidDetails: string = 'Enter valid {field}';
  public static minimumMinutes: string = 'Minimum 50 minutes needs to be added';
  public static validNumberCountryCode: string = 'Enter valid phone number with country code';


  public order_status: Record<number,string> = {
    1 : 'Pending',
    2 : 'Accepted',
    3 : 'Delivered',
    4 : 'Payment-pending',
    5 : 'Cancelled',
    6 : 'Processing',
    7 : 'On hold',
    8 : 'Completed',
    9 : 'Refunded',
    10 : 'Failed',
    11 : 'Unknown'
  }

  public analytics_help_text = {
    active: 'Total conversations which are yet to complete 24 hour window',
    open: 'Total conversations which are assigned to someone but not closed, may include conversations outside 24 hours window.',
    subscribed: 'Total conversations conusmed based on your Subscription',
    uic: 'A conversation starts when the first business message is delivered and ends 24 hours later. Conversais marked as UIC when first message sent to business is by your customer',
    status: {
      all:'Denotes your connection status with Facebook. Status can be Connected, disconnected etc',
      restricted: 'Your number has reached its 24-hour messaging limit and can no longer send messages to contacts. Please wait until the messaging limit is reset to send messages.',
      flagged: 'Your quality rating reaches to low state. You can\'t upgrade messaging limit tiers during the Flagged phase. If the message quality improves to a high or medium state by the seventh day from when your status was moved to Flagged, the status will return to Connected. If the quality rating doesn\'t improve, your status will still return to Connected, but you\'ll be placed in a lower',
    },
    quality_rating: 'This is rating of your account quality. Green is best, Red is worst',
    messaging_limit: 'This is per day conversation limit of your business. If you run campaign for 7 days rolling period to unique users upto your current BIC limit, your limit will be increased to next slot upto unlimited',
    new: 'Conversations by first time visitors of the business',
    monthly_free_uic: 'Free conversations consumed out of 1000. You will be charged for UIC after 1000 free conversations are over.',
    active_user: 'Total users who have read message sent by business.',
    interactive_user: 'Total users who have read your message and replied back.',
    direct_customers_added: 'Users who reached your business by sending first message on WhatsApp.',
    manual_customers_added: 'Users added by Excel sheet.',
    undelivered : 'Messages sent by business but not yet delivered to contact',
    delivered : 'Messages sent by business and delivered to contact',
    read : 'Messages sent by business and read by contact',
    received : 'Messages sent by cutomer  to business',
    failed : 'Messages failed to send by business.',
    conversation_type: 'Conversation type depends on type of message sent or received. It could be Marketing, Service, Utility or Authentication.',
    marketing : 'Total no of Conversations created by Marketing templates.',
    utility : 'Total no of Conversations created by Utility templates.',
    authentication : 'Total no of Conversations created Authentication templates',
    service : 'Total no of Conversations created by contact sending first message.',
    free_entry_point : 'The number of messaging conversations on WhatsApp that are free of charge because the conversations start from specific entry points, which include Ads that click to WhatsApp and Facebook Page CTAs. The conversation window for each free entry point conversation is 72-hours. Only the first conversation that starts from the entry point is free of charge. Subsequent conversations with the user are charged.',
    free : 'You are not charged for these conversations.',
    paid : 'You are charged for these conversations.',
    total : 'Total conversations including Free and Paid both',
  }

  
  public PartnerKeys: Record<string,any>  = {
    vibtree: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: '7ccd51dc-b092-440e-824f-b89eb4a21a14'
    },
    behindthesheen: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: 'b0957aa4-896f-4049-b888-e940f6e56fcd'
    },
    brandswaggin: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: 'e35e2357-e17c-4ca3-bb35-c740263ea0d4'
    },
    elevatewithai: {
      baseUrl: AppSettings.UI_BASE_URL,
      code: '5227d234-b50b-482f-b57c-1b894aec8078'
    }
  }

  public AiAgentActions: Record<string,string> = {
    "user_hangup": "Call ended by the caller.",
    "agent_hangup": "Call ended by the AI Agent.",
    "call_transfer": "AI Agent transferred call to ",
    "voicemail_reached": "Call ended by AI Agent.",
    "inactivity": "Call ended by AI Agent.",
    "machine_detected": "Call ended by AI Agent.",
    "concurrency_limit_reached": "Call not Connected.",
    "dial_busy": "Call not Connected.",
    "dial_failed": "Call not Connected.",
    "dial_no_answer": "Call not Connected.",
    "error_llm_websocket_open": "Call not Connected.",
    "error_llm_websocket_lost_connection": "Call ended by AI Agent.",
    "error_llm_websocket_runtime": "Call ended by AI Agent.",
    "error_llm_websocket_corrupt_payload": "Call ended by AI Agent.",
    "error_frontend_corrupted_payload": "Call ended by AI Agent.",
    "error_twilio": "Call not Connected.",
    "error_no_audio_received": "Call ended by AI Agent.",
    "error_asr": "Call ended by AI Agent.",
    "error_retell": "Call ended by AI Agent.",
    "error_unknown": "Call ended by AI Agent.",
    "" : ""
  }
  
}

export enum GlobalEnums {
  male = 1,
  female = 2,
  last_week = 1,
  last_month = 2,
  custom = 3,
  inProgress = '2',
  scheduled = '1',
  completed = '3',
  failed = '4',
  cancelled = '5',
  preparing = '7',
  isPaused = '6',
  approved = 1,
  inReview = 0,
  rejected = 2,
  inAppeal = 5,
  paused = 7,
  disabled = 4,
  payment_success = 3001,
  payment_failed = 3002,
  payment_already_done = 3003,
  available = "available",
  busy = "busy",
  away = "away",
  dnd = "dnd",
  in_call = "in_call",
  tran_now = "tran_now",
  talk_first = "talk_first",
}


export enum TemplateHeaderTypes {
  image = 'IMAGE',
  video = 'VIDEO',
  text = 'TEXT',
  document = 'DOCUMENT',
  location = 'LOCATION'
}

export enum CallEnum {
  in_progress = 'in-progress',
  completed = 'completed',
  inbound = 'inbound',
  outbound = 'outbound',
  failed = 'failed',
  ringing = 'ringing',
  initiated = 'initiated',
  conference_end = 'conference-end',
}

export enum UserStatus {
  active = 'active',
  invited = 'invited',
  removed = 'removed',
  available = 'available',
  away = 'away',
  incall = 'in_call',
}
