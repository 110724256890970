
<div class="getting-started-steps">
  <div class="container-heading">
    <span class="heading">Getting Started <span style="cursor: pointer;" (click)="close.emit(true)"><icon [icon]="'x'" [iconColor]="'#64748B'" [size]="'20px'"></icon></span></span>
    <span class="description">Everything you need to setup OneInbox.</span>
  </div>
  <div class="activity-tab">
    <div class="activity-item">
      <span class="icon" *ngIf="!sharedDataService.business.is_agent_created">
        <icon [icon]="'aiagents'" [iconColor]="'#6B7280'" [size]="'24px'"></icon>              
      </span>
      <span class="icon" *ngIf="sharedDataService.business.is_agent_created" style="background: #E9D5FF;">
        <icon [icon]="'check'" [iconColor]="'#7E22CE'" [size]="'24px'"></icon>              
      </span>
      <div class="activity-details">
        <div style="display: flex;flex-direction: column;gap: 2px;">
          <ng-container *ngIf="!sharedDataService.business.is_agent_created">
            <span class="time">Create AI agent</span>
            <span class="type">Use our built-in template or start from scratch.</span>
          </ng-container>
          <ng-container *ngIf="sharedDataService.business.is_agent_created">
            <span class="time"><del>Create AI agent</del></span>
            <span class="type"><del>Use our built-in template or start from scratch.</del></span>
          </ng-container>
        </div>
        <div class="hor-8px" *ngIf="sharedDataService.gettingStartedStepCount === 1">
          <save-button [saveButtonText]="'Create agent'" (saveClicked)="createAgent()"></save-button>
        </div>
      </div>
    </div>
    <span class="activity-divider"><hr></span>
      <div class="activity-item">
        <span class="icon" *ngIf="!sharedDataService.business.is_agent_test">
          <icon [icon]="'calls'" [iconColor]="'#6B7280'" [size]="'24px'"></icon>              
        </span>
        <span class="icon" *ngIf="sharedDataService.business.is_agent_test" style="background: #E9D5FF;">
          <icon [icon]="'check'" [iconColor]="'#7E22CE'" [size]="'24px'"></icon>              
        </span>
        <div class="activity-details">
          <div style="display: flex;flex-direction: column;gap: 2px;">
            <ng-container *ngIf="!sharedDataService.business.is_agent_test">
              <span class="time">Test AI agent</span>
              <span class="type">Test your AI agent's performance through text or call.</span>
            </ng-container>
            <ng-container *ngIf="sharedDataService.business.is_agent_test">
              <span class="time"><del>Test AI agent</del></span>
              <span class="type"><del>Test your AI agent's performance through text or call.</del></span>
            </ng-container>
          </div>
          <div class="hor-8px" *ngIf="sharedDataService.gettingStartedStepCount === 2">
            <save-button [saveButtonText]="'Web call'" [loading]="loaderCall" (saveClicked)="testCall()"></save-button>
          </div>
        </div>
      </div>
      <span class="activity-divider"><hr></span>
      <div class="activity-item">
        <span class="icon" *ngIf="!sharedDataService.business.is_agent_deployed">
          <icon [icon]="'preview'" [iconColor]="'#6B7280'" [size]="'24px'"></icon>              
        </span>
        <span class="icon" *ngIf="sharedDataService.business.is_agent_deployed" style="background: #E9D5FF;">
          <icon [icon]="'check'" [iconColor]="'#7E22CE'" [size]="'24px'"></icon>              
        </span>
        <div class="activity-details">
          <div style="display: flex;flex-direction: column;gap: 2px;">
            <ng-container *ngIf="!sharedDataService.business.is_agent_deployed">
              <span class="time">Deploy AI agent</span>
              <span class="type">Deploy your AI agent on a phone number or website.</span>
            </ng-container>
            <ng-container *ngIf="sharedDataService.business.is_agent_deployed">
              <span class="time"><del>Deploy AI agent</del></span>
              <span class="type"><del>Deploy your AI agent on a phone number or website.</del></span>
            </ng-container>
          </div>
          <div class="hor-8px" *ngIf="sharedDataService.gettingStartedStepCount === 3">
            <secondary-button [buttonText]="'Widget'" (clicked)="createWidget()"></secondary-button>
            <save-button [saveButtonText]="'Phone number'" (saveClicked)="addNumber()"></save-button>
          </div>
        </div>
      </div>
      <span class="activity-divider"><hr></span>
      <div class="activity-item">
        <span class="icon" *ngIf="!sharedDataService.business.is_live">
          <icon [icon]="'signal'" [iconColor]="'#6B7280'" [size]="'24px'"></icon>              
        </span>
        <span class="icon" *ngIf="sharedDataService.business.is_live" style="background: #E9D5FF;">
          <icon [icon]="'check'" [iconColor]="'#7E22CE'" [size]="'24px'"></icon>              
        </span>
        <div class="activity-details">
          <div style="display: flex;flex-direction: column;gap: 2px;">
            <ng-container *ngIf="!sharedDataService.business.is_live">
              <span class="time">Go live</span>
              <span class="type">Make call on your phone number or make a call using the widget added to your website</span>
            </ng-container>
            <ng-container *ngIf="sharedDataService.business.is_live">
              <span class="time"><del>Go live</del></span>
              <span class="type"><del>Make call on your phone number or make a call using the widget added to your website</del></span>
            </ng-container>
          </div>
        </div>
      </div>
  </div>
</div>
