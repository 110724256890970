<div class="edit-note-container" [ngStyle]="{'min-width': isEdit ? '300px' : '0'}">
  <div
    class="note-content"
    *ngIf="!isEdit"
    [innerHtml]="note | boldItalic"
    [ngStyle]="{'background': contact ? '#FFFFFF' : '#F3F4F6'}"
    style="white-space: pre-wrap; line-break: auto;overflow-wrap: break-word;"
  ></div>
  <div class="note-edit-actions" *ngIf="!contact && !disable">
    <span (click)="isEdit = true"><icon [icon]="'edit'" [iconColor]="'#6B7280'"></icon></span>
    <span (click)="delete.emit(true)"><icon [icon]="'delete'" [iconColor]="'#6B7280'"></icon></span>
  </div>
  <div class="note-editing-container" *ngIf="isEdit">
    <textarea type="text" [(ngModel)]="inputNote"></textarea>
    <div class="edit-action-buttons">
      <span class="emoji-button">
        <button
          type="button"
          class="btn btn-emoji"
          id="italic"
          style="padding: 0;"
          (clickOutside)="showEmojiPicker = false"
          >
            <icon [icon]="'emoji_icon'" (click)="openEmojiPicker()"></icon>
            <emoji-mart
              *ngIf="showEmojiPicker"
              class="emoji-mart"
              (emojiSelect)="addEmoji($event)"
              title="Pick your emoji…"
              [darkMode]="false"
            ></emoji-mart>
        </button>
      </span>
      <span class="note-edit-footer">
        <button class="note-edit-cancel" (click)="cancelNote()">Cancel</button>
        <button class="note-edit-save" (click)="saveNote()">Save</button>
      </span>
    </div>
  </div>
</div>