import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedDataService } from 'src/app/services/shared-data.service';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';
import Regions from 'wavesurfer.js/dist/plugins/regions';
import Hover from 'wavesurfer.js/dist/plugins/hover'


@Component({
  selector: 'audio-stream',
  templateUrl: './audio-stream.component.html',
  styleUrls: ['./audio-stream.component.css']
})
export class AudioStreamComponent implements OnInit, OnChanges, AfterViewInit {

  constructor(
    public sharedDataService: SharedDataService,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/download.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'play_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/play_circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'pause_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/pause_circle.svg'
      )
    );
   }
   
   @Input() audioUrl: string = '';
   @ViewChild('waveform') waveformRef!: ElementRef;
   isPlaying: boolean = false;
   currentTime: number = 0;
   duration: number = 0;
   wave!: WaveSurfer;
   waveLoading: boolean = false;
   
  ngAfterViewInit(): void {
    this.currentTime = 0;
    let waveOptions = {
      container: this.waveformRef.nativeElement,
      waveColor: '#E5E7EB',
      progressColor: 'var(--primary-color)',
      cursorColor: 'navy',
      barWidth: 2,
      height: 40,
      barHeight: 10,
      barGap: 2,
      plugins: [
        Hover.create({
          lineColor: '#5521B5',
          lineWidth: 2,
          labelBackground: '#555',
          labelColor: '#fff',
          labelSize: '12px',
        }),
      ],
    }
    if(!this.wave) {
      this.wave = WaveSurfer.create(waveOptions);
      this.wave.load(this.audioUrl);
    } else {
      this.wave.destroy();
      this.wave = WaveSurfer.create(waveOptions);
      this.wave.load(this.audioUrl);
    }
  
    // Event listener for updating current time
    this.wave.on('audioprocess', () => {
      this.currentTime = this.wave.getCurrentTime();
    });
    this.wave.on('load', () => {
      this.waveLoading = true;
    });
    this.wave.on('redrawcomplete', () => {
      this.waveLoading = false;
    });
  
    // Event listener for getting duration once the audio is ready
    this.wave.on('ready', () => {
      this.duration = this.wave.getDuration();
    });
    
    this.wave.on('finish', () => {
      this.isPlaying = false;
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['audioUrl']) {
      this.ngAfterViewInit();
    }
  }

  toggleAudio() {
    if(!this.isPlaying) {
      this.wave.play();
      this.isPlaying = true;
    } else {
      this.wave.pause();
      this.isPlaying = false;
    }
  }

  updateTime(event: any) {
    this.currentTime = event.target.currentTime;
    this.duration = event.target.duration;
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${this.pad(minutes)}:${this.pad(remainingSeconds)}`;
  }

  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  downloadAudio() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.audioUrl);
    link.setAttribute('download', 'call_recording.mp4');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  forward() {
    this.wave.skip(10);
  }

  backward() {
    this.wave.skip(-10);
  }
}
