import { InfoBase } from "./infobase"

export class AiAgent {
  private _id: number
  private _business: number
  private _agent_id: string
  private _llm_websocket_url: string
  private _agent_name: string
  private _voice_id: string
  private _voice_temperature: number
  private _voice_speed: number
  private _responsiveness: number
  private _interruption_sensitivity: number
  private _enable_backchannel: boolean
  private _backchannel_frequency: number
  private _backchannel_words: any
  private _reminder_trigger_ms: number
  private _reminder_max_count: number
  private _ambient_sound: string
  private _language: string
  private _webhook_url: string
  private _boosted_keywords: any
  private _opt_out_sensitive_data_storage: boolean
  private _pronunciation_dictionary: any
  private _normalize_for_speech: boolean
  private _end_call_after_silence_ms: number
  private _last_modification_timestamp: number
  private _is_active: boolean
  private _created_at: string
  private _updated_at: string
  private _general_prompt: string;
  private _time_zone: string;
  private _live_widget_count: number;
  private _number: number;
  private _profile_pic: string;
  private _infobase_assets: InfoBase[];
  private _raw_general_prompt: string;
  private _max_call_duration_ms: number;

    public get max_call_duration_ms(): number {
        return this._max_call_duration_ms;
    }

    public set max_call_duration_ms(max_call_duration_ms: number) {
        this._max_call_duration_ms = max_call_duration_ms;
    }


    public get raw_general_prompt(): string {
        return this._raw_general_prompt;
    }

    public set raw_general_prompt(raw_general_prompt: string) {
        this._raw_general_prompt = raw_general_prompt;
    }


    public get infobase_assets(): InfoBase[] {
        return this._infobase_assets;
    }

    public set infobase_assets(infobase_assets: InfoBase[]) {
        this._infobase_assets = infobase_assets;
    }


    public get profile_pic(): string {
        return this._profile_pic;
    }

    public set profile_pic(profile_pic: string) {
        this._profile_pic = profile_pic;
    }


    public get live_widget_count(): number {
        return this._live_widget_count;
    }

    public set live_widget_count(live_widget_count: number) {
        this._live_widget_count = live_widget_count;
    }

    public get number(): number {
        return this._number;
    }

    public set number(number: number) {
        this._number = number;
    }


    public get time_zone(): string {
        return this._time_zone;
    }

    public set time_zone(time_zone: string) {
        this._time_zone = time_zone;
    }


    public get general_prompt(): string {
        return this._general_prompt;
    }

    public set general_prompt(general_prompt: string) {
        this._general_prompt = general_prompt;
    }


  constructor(agentJson: any) {
    this._id = agentJson?.id;
    this._business = agentJson?.business;
    this._agent_id = agentJson?.agent_id;
    this._llm_websocket_url = agentJson?.llm_websocket_url;
    this._agent_name = agentJson?.agent_name;
    this._voice_id = agentJson?.voice_id;
    this._voice_temperature = agentJson?.voice_temperature;
    this._voice_speed = agentJson?.voice_speed;
    this._responsiveness = agentJson?.responsiveness;
    this._interruption_sensitivity = agentJson?.interruption_sensitivity;
    this._enable_backchannel = agentJson?.enable_backchannel;
    this._backchannel_frequency = agentJson?.backchannel_frequency;
    this._backchannel_words = agentJson?.backchannel_words;
    this._reminder_trigger_ms = agentJson?.reminder_trigger_ms;
    this._reminder_max_count = agentJson?.reminder_max_count;
    this._ambient_sound = agentJson?.ambient_sound;
    this._language = agentJson?.language;
    this._webhook_url = agentJson?.webhook_url;
    this._boosted_keywords = agentJson?.boosted_keywords;
    this._opt_out_sensitive_data_storage = agentJson?.opt_out_sensitive_data_storage;
    this._pronunciation_dictionary = agentJson?.pronunciation_dictionary;
    this._normalize_for_speech = agentJson?.normalize_for_speech;
    this._end_call_after_silence_ms = agentJson?.end_call_after_silence_ms;
    this._last_modification_timestamp = agentJson?.last_modification_timestamp;
    this._is_active = agentJson?.is_active;
    this._created_at = agentJson?.created_at;
    this._updated_at = agentJson?.updated_at;
    this._general_prompt = agentJson?.general_prompt;
    this._time_zone = agentJson?.time_zone;
    this._live_widget_count = agentJson?.live_widget_count;
    this._number = agentJson?.number;
    this._profile_pic = agentJson?.profile_pic;
    this._infobase_assets = [];
    agentJson?.infobase_assets?.forEach((element: any) => {
        this._infobase_assets.push(new InfoBase(element));
    })
    this._raw_general_prompt = agentJson?.raw_general_prompt;
    this._max_call_duration_ms = agentJson?.max_call_duration_ms;
  }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get business(): number {
        return this._business;
    }

    public set business(business: number) {
        this._business = business;
    }

    public get agent_id(): string {
        return this._agent_id;
    }

    public set agent_id(agent_id: string) {
        this._agent_id = agent_id;
    }

    public get llm_websocket_url(): string {
        return this._llm_websocket_url;
    }

    public set llm_websocket_url(llm_websocket_url: string) {
        this._llm_websocket_url = llm_websocket_url;
    }

    public get agent_name(): string {
        return this._agent_name;
    }

    public set agent_name(agent_name: string) {
        this._agent_name = agent_name;
    }

    public get voice_id(): string {
        return this._voice_id;
    }

    public set voice_id(voice_id: string) {
        this._voice_id = voice_id;
    }

    public get voice_temperature(): number {
        return this._voice_temperature;
    }

    public set voice_temperature(voice_temperature: number) {
        this._voice_temperature = voice_temperature;
    }

    public get voice_speed(): number {
        return this._voice_speed;
    }

    public set voice_speed(voice_speed: number) {
        this._voice_speed = voice_speed;
    }

    public get responsiveness(): number {
        return this._responsiveness;
    }

    public set responsiveness(responsiveness: number) {
        this._responsiveness = responsiveness;
    }

    public get interruption_sensitivity(): number {
        return this._interruption_sensitivity;
    }

    public set interruption_sensitivity(interruption_sensitivity: number) {
        this._interruption_sensitivity = interruption_sensitivity;
    }

    public get enable_backchannel(): boolean {
        return this._enable_backchannel;
    }

    public set enable_backchannel(enable_backchannel: boolean) {
        this._enable_backchannel = enable_backchannel;
    }

    public get backchannel_frequency(): number {
        return this._backchannel_frequency;
    }

    public set backchannel_frequency(backchannel_frequency: number) {
        this._backchannel_frequency = backchannel_frequency;
    }

    public get backchannel_words(): any {
        return this._backchannel_words;
    }

    public set backchannel_words(backchannel_words: any) {
        this._backchannel_words = backchannel_words;
    }

    public get reminder_trigger_ms(): number {
        return this._reminder_trigger_ms;
    }

    public set reminder_trigger_ms(reminder_trigger_ms: number) {
        this._reminder_trigger_ms = reminder_trigger_ms;
    }

    public get reminder_max_count(): number {
        return this._reminder_max_count;
    }

    public set reminder_max_count(reminder_max_count: number) {
        this._reminder_max_count = reminder_max_count;
    }

    public get ambient_sound(): string {
        return this._ambient_sound;
    }

    public set ambient_sound(ambient_sound: string) {
        this._ambient_sound = ambient_sound;
    }

    public get language(): string {
        return this._language;
    }

    public set language(language: string) {
        this._language = language;
    }

    public get webhook_url(): string {
        return this._webhook_url;
    }

    public set webhook_url(webhook_url: string) {
        this._webhook_url = webhook_url;
    }

    public get boosted_keywords(): any {
        return this._boosted_keywords;
    }

    public set boosted_keywords(boosted_keywords: any) {
        this._boosted_keywords = boosted_keywords;
    }

    public get opt_out_sensitive_data_storage(): boolean {
        return this._opt_out_sensitive_data_storage;
    }

    public set opt_out_sensitive_data_storage(opt_out_sensitive_data_storage: boolean) {
        this._opt_out_sensitive_data_storage = opt_out_sensitive_data_storage;
    }

    public get pronunciation_dictionary(): any {
        return this._pronunciation_dictionary;
    }

    public set pronunciation_dictionary(pronunciation_dictionary: any) {
        this._pronunciation_dictionary = pronunciation_dictionary;
    }

    public get normalize_for_speech(): boolean {
        return this._normalize_for_speech;
    }

    public set normalize_for_speech(normalize_for_speech: boolean) {
        this._normalize_for_speech = normalize_for_speech;
    }

    public get end_call_after_silence_ms(): number {
        return this._end_call_after_silence_ms;
    }

    public set end_call_after_silence_ms(end_call_after_silence_ms: number) {
        this._end_call_after_silence_ms = end_call_after_silence_ms;
    }

    public get last_modification_timestamp(): number {
        return this._last_modification_timestamp;
    }

    public set last_modification_timestamp(last_modification_timestamp: number) {
        this._last_modification_timestamp = last_modification_timestamp;
    }

    public get is_active(): boolean {
        return this._is_active;
    }

    public set is_active(is_active: boolean) {
        this._is_active = is_active;
    }

    public get created_at(): string {
        return this._created_at;
    }

    public set created_at(created_at: string) {
        this._created_at = created_at;
    }

    public get updated_at(): string {
        return this._updated_at;
    }

    public set updated_at(updated_at: string) {
        this._updated_at = updated_at;
    }

}
