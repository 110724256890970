import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';
import { AuthGuard } from './helpers/authGuard';
import { SubscriptionExpiredComponent } from './components/shared/subscription-expired/subscription-expired.component';
import { AgencyExpiredComponent } from './components/shared/agency-expired/agency-expired.component';
import { InvalidPageComponent } from './components/shared/invalid-page/invalid-page.component';
import { AgencyGuard } from './helpers/agency.guard';

//TODO Add resolver to login so that the login module won't load when token is available
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'agents',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/ai-agents/ai-agents.module').then((m) => m.AiAgentsModule),
  },
  {
    path: 'contacts',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/contacts/contacts.module').then((m) => m.ContactsModule),
  },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/clients/clients.module').then((m) => m.ClientsModule),
  },
  // {
  //   path: 'infobase',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./components/infobase/infobase.module').then((m) => m.InfoBaseModule),
  // },
  // {
  //   path: 'templates',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./components/templates/templates.module').then((m) => m.TemplatesModule),
  // },
  // {
  //   path: 'chats',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./components/chats/chats.module').then((m) => m.ChatsModule),
  // },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'billing',
    canActivate: [AuthGuard, AgencyGuard],
    loadChildren: () =>
      import('./components/billing/billing.module').then(
        (m) => m.BillingModule
      ),
  },
  {
    path: 'calls',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/calls/calls.module').then(
        (m) => m.CallsModule
      ),
  },
  // {
  //   path: 'analytics',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./components/analytics/analytics.module').then((m) => m.AnalyticsModule),
  // },
  {
    path: 'getting-started',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/getting-started/getting-started.module').then((m) => m.GettingStartedModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'subscription-expired',
    component: SubscriptionExpiredComponent,
  },
  {
    path: 'agency-expired',
    component: AgencyExpiredComponent,
  },
  {
    path: 'invalid',
    component: InvalidPageComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
