import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebSocketService } from './services/web-socket.service';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { ToasterService } from './services/toastr.service';
import { SharedDataService } from './services/shared-data.service';
import { LoggedUser } from './models/logged-user';
import { SubscriptionService } from './services/subscription.service';
import { Business } from './models/business';
import { environment } from 'src/environments/environment';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { CommonService } from './services/common.service';
import { Title } from '@angular/platform-browser';
import { WorkerService } from './services/worker.service';

import posthog from 'posthog-js'
import { RetellService } from './services/retell.service';
import { AuthenticationService } from './services/authentication.service';
import { StripeService } from './services/stripe.service';
import { AgencyService } from './services/agency.service';
import { v4 as uuidv4 } from 'uuid';

posthog.init('phc_j64rRtkbfXKpnwDRp9vmhAlB2PJeMLi2nIbxrW78JI7', { api_host: 'https://app.posthog.com' });

import Intercom, { hide } from '@intercom/messenger-js-sdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'oneinbox-app';
  status!: OnlineStatusType;
  onlineStatusCheck: any = OnlineStatusType;
  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  business: Business = new Business(JSON.parse(localStorage.getItem('business')!));

  constructor(
    private websocketService: WebSocketService,
    private router: Router,
    private onlineStatusService: OnlineStatusService,
    public toastr: ToasterService,
    public sharedService: SharedDataService,
    public subscriptionService: SubscriptionService,
    private _commonService: CommonService,
    private remoteConfig: AngularFireRemoteConfig,
    private renderer: Renderer2,
    public titleService: Title,
    public workerService: WorkerService, //Don't remove this line
    public retellService: RetellService,
    private _activatedRoute: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _stripeService: StripeService,
    private _agencyService: AgencyService,
  ) {
    titleService.setTitle(_agencyService.brand);
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // Retrieve Online status Type
      console.log('Internet Connection ' + status);
      this.status = status;
      if (status) {
        websocketService.setInternetConnection(true);
      } else {
        websocketService.setInternetConnection(false);
      }
    });
  }

  ngOnInit(): void {
    if(location.origin.includes('https://cname.oneinbox.ai')) {
      this.router.navigate(['/invalid'])
      return ;
    }
    if(location.origin.includes('https://app.oneinbox.ai')) {
      this._agencyService.isAgency = false;
    }
    this._agencyService.getAssets().subscribe({
      next: resp => {
        console.log(resp);
        if(resp.body.brand) {
          this._agencyService.brand = resp.body.brand;
          this.titleService.setTitle(this._agencyService.brand);
        }
        if(resp.body.dark_logo) this._agencyService.darkLogo = resp.body.dark_logo;
        if(resp.body.light_logo) this._agencyService.lightLogo = resp.body.light_logo;
        if(resp.body.favicon) {
          this._agencyService.favicon = resp.body.favicon;
          this.changeFavicon(this._agencyService.favicon);
        }
        if(resp.body.support_email_id) this._agencyService.supportEmail = resp.body.support_email_id
        this._agencyService.isPricingEnabled = resp.body.plans_and_billings;
      }, error: error => {
        console.log(error);
        this.toastr.error(error);
      }
    })
    this._activatedRoute.queryParams.subscribe(params => {
      if(params['client_token']) {
        sessionStorage.setItem('client_token', 'JWT ' +params['client_token']);
        this.sharedService.isLoggedIn = true;
        // this._router.navigate(['/agents']);
        console.log(params['client_token'])
        this._authenticationService.getUserProfile().subscribe({
          next: resp => {
            if(resp.body.code === 200) {
              localStorage.setItem('user',JSON.stringify(resp.body.data));
              this.user = new LoggedUser(resp.body.data);
              this.sharedService.loggedUser = this.user;
            }
          }
        })
      } else {
        if(localStorage.getItem('token')) {
          this._authenticationService.getUserProfile().subscribe({
            next: resp => {
              if(resp.body.code === 200) {
                localStorage.setItem('user',JSON.stringify(resp.body.data));
                this.user = new LoggedUser(resp.body.data);
                this.sharedService.loggedUser = this.user;
              }
            }
          })
        }
      }
    })

    this.subscriptionService.setSubscription(this.business);
    // setInterval(() => {
    //   let token = localStorage.getItem('token');
    //   // if (token && this.websocketService.websocketOpened)
    //     // this.checkWebsocketConnection();
    // }, 15 * 1000);
    if(localStorage.getItem('token')) {
      this._commonService.getBusiness();
      this._stripeService.getPaymentGatewayKeys().subscribe({
        next: resp =>{
          console.log(resp);
          this.sharedService.paymentGatewayKeys.is_default = resp.body.data.is_default;
          if(resp.body.data.access_key) {
            if(this.sharedService.paymentGatewayKeys.is_default) this.sharedService.paymentGatewayKeys.default = resp.body.data.access_key;
            else this.sharedService.paymentGatewayKeys.stripe = resp.body.data.access_key;
          }
        }
      })
      let cloudNumbers =  JSON.parse(localStorage.getItem('cloud-numbers')!);
      this.sharedService.setCloudNumbers(cloudNumbers)
    }
    this.remoteConfig.fetchAndActivate().then(() => {
      console.log('Remote Config activated');

      //For downtime of system
      this.getValueByKey('oneinbox_downtime').then(resp => {
        console.log(typeof resp?._value)
        console.log("is downtime now: "+ resp?._value);
        if(resp?._value === 'true') {
          this.sharedService.remoteConfig.downtime = true;
        }
      }).catch( error => {
        console.log(error);
      })

      // //Frill widget enabling and disabling
      // this.getValueByKey('frill').then(resp => {
      //   console.log(typeof resp?._value)
      //   console.log("frill: "+ resp?._value);
      //   if(resp?._value === 'true') {
      //     this.sharedService.remoteConfig.frill = true;
      //   } else {
      //     this.sharedService.remoteConfig.frill = false;
      //   }
      // }).catch( error => {
      //   console.log(error);
      //   this.sharedService.remoteConfig.frill = false;
      // })
    });

    this.changeFavicon(this._agencyService.favicon);

    if(this.user?.id) {
      Intercom({
        app_id: 'irngw58q',
        user_id: this.user.id.toString(), // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: this.user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: this.user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: (new Date()).getTime(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
      document.getElementById('intercom-container-body')?.attributeStyleMap.append('display','none');
    }

  }

  public async getValueByKey(key: string): Promise<any> {
    return this.remoteConfig.getValue(key).then((value) => {
      return value;
    }).catch((err) => {
      return err;
    });
   }


  getLeftPadding() {
    if(this.sharedService.hoverActive) return 'padding-left: 30px';
    else {
      if(this.sharedService.responsiveMenuBarOpen) return 'padding-left: 300px';
      else return 'padding-left: 30px';
    }
  }

  changeFavicon(iconPath: string): void {
    // const head = this.renderer.selectRootElement('head');
    const existingFavicon = this.renderer.selectRootElement('link[rel="icon"]');
    
    if (existingFavicon) {
      // Update the existing favicon
      this.renderer.setAttribute(existingFavicon, 'href', iconPath);
    } else {
      // Create a new link element for the new favicon
      const newFavicon = this.renderer.createElement('link');
      this.renderer.setAttribute(newFavicon, 'rel', 'icon');
      this.renderer.setAttribute(newFavicon, 'type', 'image/x-icon');
      this.renderer.setAttribute(newFavicon, 'href', iconPath);
    
      // Append the new favicon to the head
      this.renderer.appendChild(this.renderer.selectRootElement('head'), newFavicon);
    }
  }

}
