<div class="commands-input-container">
  <div
    [mentionConfig]="mentionConfig" 
    [mention]="variables"
    [mentionListTemplate]="mentionListTemplate"
    (itemSelected)="itemSelected($event)"
    (keyup)="emitChange()"
    contenteditable="true"
    id="my-content"
    [attr.data-placeholder]="placeholder"
    class="editable-div"
    [ngStyle]="{'height': height }"
  ></div>
  <ng-template #mentionListTemplate let-item="item" class="command-dropdown-menu">
    <span class="command-list-item">{{item?.label}}</span>
  </ng-template>
  <span class="character-count" *ngIf="count && ((htmlText?.length ?? 0) > 160)">{{(htmlText?.length ?? 0) % 160}}/{{(((htmlText?.length ?? 0) / 160)+ 1) | number:'1.0-0'}}</span>
</div>
