<div class="textarea-input-container">
  <p class="field-name-count">
    <span class="field-name">{{fieldName}}</span>
    <span class="text-count" *ngIf="maxChars">{{inputText?.length}}/{{maxChars}}</span>
  </p>
  <textarea type="text" 
    [ngStyle]="{'height' : height}"
    [(ngModel)]="inputText" 
    (keypress)="checkLength($event)" 
    (keyup)="restrictLength()"
    [placeholder]="placeholder" 
    (keyup.enter)="enterClicked.emit(inputText)"
    (focusout)="focusout.emit(true)"
    (ngModelChange)="textChange.emit(inputText)"></textarea>
</div>