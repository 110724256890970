<hlm-dialog [state]="isOpen ? 'open' : 'closed'" (closed)="close.emit(true)">
  <hlm-dialog-content *brnDialogContent="let ctx">
    <div class="card-fields">
      <div class="card-heading">
        <span>{{heading}}</span>
      </div>
      <div  class="description" [innerHTML]="description"></div>
    </div>
    <div class="footer-buttons">
      <cancel-button
      [cancelButtonText]="negativeButtonText"
      (cancelClicked)="close.emit(true)"
      ></cancel-button>
      <save-button 
        [saveButtonText]="positiveButtonText" 
        (saveClicked)="onSuccess.emit(true)"
      ></save-button>
    </div>
  </hlm-dialog-content>
</hlm-dialog>
