import { Injectable } from '@angular/core';
import { GlobalEnums } from '../constants';
import { AudienceLabel, AudienceSet } from '../models/audience-set';
import { Label } from '../models/label';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { AuthenticationService } from './authentication.service';
import { WebSocketService } from './web-socket.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { SharedDataService } from './shared-data.service';
import { SubscriptionService } from './subscription.service';
import { Subscription } from '../models/subscription';
import { Business } from '../models/business';
import { SwPush } from '@angular/service-worker';
import { BusinessService } from './business.service';
import { VoiceService } from './voice.service';
import { SubscriptionPlan } from '../models/subscription_plan';
import { StripeService } from './stripe.service';
import { shutdown } from '@intercom/messenger-js-sdk';

export interface NumberDetails {
  countryCode: string,
  callingCode: string,
  number: string,
  flag: string,
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  phoneNumberUtil = PhoneNumberUtil.getInstance();
  business: Business = new Business(JSON.parse(localStorage.getItem('business')!));
  VAPID_PUBLIC_KEY: string = 'BCSZFL3US6A8eUT4U8qe-FtSX61YiYWXBugLdEEWRuislh0VZfa86akAqHMcH7L3-Fee-Kb-mD1m6wf5OBX_iIg';

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router, 
    private logger: NGXLogger,
    public webSocketService: WebSocketService,
    private sharedService: SharedDataService,
    private subscriptionService: SubscriptionService,
    private swPush: SwPush,
    private _businessService: BusinessService,
    private _voiceService: VoiceService,
    private _stripeService: StripeService,
  ) { }

  getFormatedJoiningDate(audienceSet: AudienceSet) {
    if(audienceSet?.date_of_joining){
      if(audienceSet?.date_of_joining == GlobalEnums.last_week.toString()) return 'Last Week';
      else if(audienceSet?.date_of_joining == GlobalEnums.last_month.toString()) return 'Last Month';
      else if(audienceSet?.date_of_joining == GlobalEnums.custom.toString()) {
        if(audienceSet?.date_of_joining_from){
          let fromDate = new Date(audienceSet.date_of_joining_from);
          let fromDateArray = fromDate.toDateString().split(' ');
          let fromDateString = fromDateArray[2]+' '+fromDateArray[1]+' '+fromDateArray[3];
          if(audienceSet?.date_of_joining_to){
            let toDate = new Date(audienceSet.date_of_joining_from);
            let toDateArray = toDate.toDateString().split(' ');
            let toDateString = toDateArray[2]+' '+toDateArray[1]+' '+toDateArray[3];
            return fromDateString+' - '+toDateString;
          } else return fromDateString +'- All'; 
        } 
        else return 'All';
      } else return 'All';
    } else return 'All';
  }

  getLabelFromAudienceLabel(audienceLabel: any): Label {
    let input = {
      name: audienceLabel.name,
      color: audienceLabel.color,
      description: '',
      id: audienceLabel.label_id,
      bg_color: audienceLabel?.bg_color,
      isChecked: false
    }
    let label = new Label(input);
    return label;
  }

  getCampaignStatus(status: string){
    if(status === GlobalEnums.completed) return 'Completed';
    else if(status === GlobalEnums.inProgress) return 'In progress';
    else if(status === GlobalEnums.failed) return 'Failed';
    else if(status === GlobalEnums.scheduled) return 'Scheduled';
    else if(status === GlobalEnums.cancelled) return 'Stopped';
    else if(status === GlobalEnums.preparing) return 'Preparing';
    else if(status === GlobalEnums.isPaused) return 'Paused';
    else return 'Completed';
  }

  getAge(audienceSet: AudienceSet){
    if(audienceSet?.from_age){
      if(audienceSet?.to_age){
        return audienceSet.from_age+' - '+audienceSet.to_age;
      } else return ''+audienceSet?.from_age+' - All';
    } else return 'All';
  }

  getGender(audienceSet: AudienceSet){
    if(audienceSet?.gender){
      if(audienceSet.gender == '1') return 'Male';
      else if(audienceSet.gender == '2') return 'Female';
      else return 'All';
    } return 'All';
  }

  getShortDate(inputDate: Date | undefined) {
    if(inputDate == undefined) return '';
    let date = new Date(inputDate);
    let dateArray = date.toDateString().split(' ');
    return dateArray[2]+ ' '+ dateArray[1];
  }

  getStringAfterDot(input: string | undefined){
    if(input == undefined) return '';
    let type = ''+input.split('.').pop();
    return type;
  }

  getStringAfterSlash(input: string | undefined){
    if(input == undefined) return '';
    let type = ''+input.split('/').pop();
    return type;
  }

  getImagePresignObject(image: File){
    let presignObj = {
      key: '/image/' + image.name.replaceAll(' ', '_'),
      content_type:
        image.name.split('.').pop() === 'png'
          ? 'image/png'
          : 'image/jpeg',
    };
    return presignObj
  }

  isValidNumber(number: string,code: string) {
    try {
      const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(
        number,code
      );
      if(this.phoneNumberUtil.isValidNumber(phoneNumber)){
        return true;
      } else {
        return false;
      }
    } catch (er) {
      return false;
    }
  }
  isValidNumber2(number: string) {
    try {
      const phoneNumber = this.phoneNumberUtil.parse(number,"ZZ");
      // phoneNumberUtil.parse(number, 
      // CountryCodeSource.UNSPECIFIED.name());
      if(this.phoneNumberUtil.isValidNumber(phoneNumber)){
        return true;
      } else {
        return false;
      }
    } catch (er) {
      return false;
    }
  }
  getCountryCodeFromNumber(number: string) {
    try {
      const phoneNumber1 = this.phoneNumberUtil.parseAndKeepRawInput(
        '+'+ number
      );
      return phoneNumber1?.getCountryCode() ? ''+phoneNumber1.getCountryCode() : '';
    } catch (er) {
      return '';
    }
  }
  getMobileNumber(number: string) {
    try {
      const phoneNumber1 = this.phoneNumberUtil.parseAndKeepRawInput(
        '+'+ number
      );
      return this.phoneNumberUtil.getNationalSignificantNumber(phoneNumber1);
    } catch (er) {
      return '';
    }
  }

  logout() {
    if(sessionStorage.getItem('client_token')) {
      window.close();
    } else {
      // if(localStorage.getItem('token')) {
      //   this.authenticationService
      //     .logout({
      //       platform: 'web',
      //     })
      //     .subscribe((resp) => {
      //       if(resp.body.code == 200){
      //         console.log('logout successful');
      //       } else {
      //         console.log(resp.body.message);
      //       }
      //     },(error)=>{
      //       console.log(error);
      //     });
      // }
      this.sharedService.isLoggedIn = false;
      this.sharedService.isWabaEnabled = true;
      this.sharedService.isPartnerBusiness = false;
      localStorage.clear();
      this.webSocketService.closeWebSocket();
      this.router.navigate(['/']);
    }
    shutdown();
  }

  getAllSubscriptions() {
    this.subscriptionService.getAllSubscriptions().subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          let subscriptionList = resp.body.data?.reverse();
          this.subscriptionService.allSubscriptions = [];
          for(var index=0;index<subscriptionList?.length;index++) {
            let subscription = new Subscription(subscriptionList[index]);
            if(subscription.is_active) {
              this.subscriptionService.allSubscriptions.unshift(subscription);
            }
            else this.subscriptionService.allSubscriptions.push(subscription);
          }
          if(this.subscriptionService.allSubscriptions.length === 0) {
            this.subscriptionService.allSubscriptions.push(new Subscription(this.business.subscription));
          }
          console.log(subscriptionList);
          console.log(this.subscriptionService.allSubscriptions);
        }
      }
    });
  }
  checkNewPlans() {
    this.subscriptionService.checkNewVersions().subscribe({
      next: resp => {
        let temp = resp.body.data;
        this.subscriptionService.newPlans = [];
        temp?.forEach((plan: any) => {
          this.subscriptionService.newPlans.push(new SubscriptionPlan(plan));
        })
        console.log(this.subscriptionService.newPlans)
      }, error: error => {
        console.log(error);
      }
    })
  }

  subscribeToNotifications() {
    if (Notification.permission !== 'granted') {
      console.warn('Notification permission not granted.');
      Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          console.log("Granted");
        } else {
          console.log("Denied");
        }
      })
      .catch((error) => {
        console.error('Error requesting notification permission:', error);
      });
    }
    setTimeout(() => {
      this.swPush.requestSubscription({
          serverPublicKey: this.VAPID_PUBLIC_KEY
        })
        .then(sub => console.log(sub))
        .catch(err => console.log("Could not subscribe to notifications"));
        this.swPush.messages.subscribe((message) => {
          console.log(message);
        });
    }, 1000)

  }

  getBusiness() {
    let business = JSON.parse(localStorage.getItem('business')!);
    this.sharedService.setBusiness(business);
    this.subscriptionService.setSubscription(business?.subscription);
    this._businessService.getBusiness().subscribe((resp)=>{
      localStorage.setItem('business', JSON.stringify(resp.body.data));
      this.sharedService.setBusiness(resp.body.data);
      this.subscriptionService.setSubscription(resp.body.data.subscription);
    });
  }

  getWhatsAppDetails() {
    this._businessService.getWhatsappDetails().subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          if(resp.body.data[0]) {
            localStorage.setItem('whatsapp', JSON.stringify(resp.body.data[0]));
            this.sharedService.setWhatsappDetails(resp.body.data[0]);
          } else {
            this.sharedService.setWhatsappDetails(null);
          }
        }
      }
    });
  }

  getInboxes() {
    this._voiceService.getInboxes().subscribe({
      next: resp => {
        console.log(resp);
        if (resp.body?.code == 200) {
          let responseList = resp.body.data;
          this.sharedService.setCloudNumbers(responseList);
        }
      }, error: error => {
        console.log(error)
      }
    })
  }

  getStripeKeys() {
    this._stripeService.getPaymentGatewayKeys().subscribe({
      next: resp =>{
        console.log(resp);
        this.sharedService.paymentGatewayKeys.is_default = resp.body.data.is_default;
        if(resp.body.data.access_key) {
          if(this.sharedService.paymentGatewayKeys.is_default) this.sharedService.paymentGatewayKeys.default = resp.body.data.access_key;
          else this.sharedService.paymentGatewayKeys.stripe = resp.body.data.access_key;
        }
      }
    })
  }
}
