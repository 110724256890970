import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.css']
})
export class TextareaInputComponent {
  
  @Input() fieldName!: string;
  @Input() inputText!: string;
  @Input() maxChars!: number;
  @Input() placeholder: string = 'Enter text';
  @Input() height: string = '320px'
  @Output() textChange = new EventEmitter<string>();
  @Output() enterClicked = new EventEmitter<string>();
  @Output() focusout = new EventEmitter<Boolean>();
  
  constructor() { }

  checkLength(event: any){
    if(this.maxChars){
      if(this.inputText?.length < this.maxChars) return true;
      else return false;
    } else return true;
  }
  restrictLength(){
    this.inputText = this.inputText.substring(0,this.maxChars);
  }

}
